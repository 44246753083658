@media only screen and (min-width: 600px) {
  .fixed-height {
    height: 10vh !important;
  }

  .custome-style {
    font-size: 19px;
    line-height: 35px;
  }

  .desktop-none {
    display: none;
  }

  /* .fixed-height-slider img{
    height: 92vh !important;
  } */
  /* .fixed-height-all-slider{
    height: 91vh !important;
  } */
}

@media only screen and (max-width: 600px) {


  .message-icon {
    width: 40px !important;
    height: 30px !important;
    font-size: 20px !important;
  }

  .what-icon {
    width: 40px !important;
    height: 30px !important;
    font-size: 20px !important;
  }

  .call-btn {
    width: 40px !important;
    height: 30px !important;
    font-size: 20px !important;
  }

  .phone-gap {
    margin-top: 16px;
  }

  .apply-btn {
    margin-bottom: 10px !important;
  }

  .slide .carousel-inner .carousel-item img {
    /* height: 200px !important; */
    /* width: auto !important; */
  }


  .ten-heading h1 {
    font-size: 1em !important;
  }


  .coloum-reverse {
    flex-direction: column-reverse;
  }

  .foot-heading {
    font-size: 14px !important;
  }

  .footer-section-2 ul li a {
    font-size: 13px;
  }

  .icon-bar a {
    padding: 4px 8px !important;
  }

  .responsive-logo-h {
    height: 33px;
  }

  .mbl-none {
    display: none;
  }

  .about-section {
    padding-top: 1rem !important;
    margin-top: -1rem !important;
  }

  .about-paragraph {
    margin-top: 0.6rem !important;
  }

  .hero-img {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .mobile-padding {
    padding-top: 2rem !important;
  }

  .round-card-content {
    margin-top: 2rem;
  }

  .testimonial-img {
    display: none !important;
  }

  .quotes-p {
    font-size: 1.4rem;
    padding: 1rem 2rem 1rem 2rem !important;
  }

  .all-products {
    display: none !important;
  }

  .mobile-resonsive {
    margin-top: 1.2rem !important;
  }

  .logo-area img {
    width: 100px;
  }

  .slider-content {
    top: 12% !important;
    left: 50%;
    transform: translate(-50%);
    text-align: center;
  }

  .slider-content h2 {
    font-size: 2.3rem !important;
    font-family: 'Italianno', cursive;
  }

  .slider-content p {
    font-size: 1.1rem !important;
  }



  .about-heading {
    font-size: 1.9rem ! important;
  }

  .about-paragraph {
    font-size: 1rem ! important;
  }

  .potato {
    font-size: 2rem !important;
    line-height: 30px !important;
  }

  .pt-c {
    font-size: 2rem !important;
  }

  .slick-prev {
    left: -12px;
  }

  .homepage .slick-next {
    right: 10px !important;
  }

  .products-section .col-md-3 {
    margin-bottom: 2rem;
  }

  .products-section .products-btn {
    margin-top: 0rem !important;
    margin-bottom: 1.5rem !important;
  }


  .products-section .products-section-heading {
    font-size: 2rem ! important;
    padding-top: 1rem !important;
  }

  .round-card-section {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .shapes {
    margin-bottom: 0rem !important;
  }

  .footer-section {
    padding-top: 0rem !important;
  }

  .number-client ul .number {
    font-size: 2rem !important;
  }

  .customer-mrgn {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .top-10 {
    top: -1% !important;
  }

  .round-card {
    padding: 2rem !important;
  }


  .gradients .col {
    margin-bottom: 3.9rem;
  }

  .shapes {
    margin-top: 0px !important;
  }

  .testmoinal-slider .slick-next {
    right: -8px;
  }


  .homepage .slick-dots {
    bottom: 10px !important;

  }


  .chips .slick-next {
    right: 10px;

  }

  .chips .slick-prev {
    left: 10px;
  }

  .phone-img {
    width: 100%;
  }
}