:root {
  --themeMain: #ffed00;
  --secondtheme: #D1C61A;
  --thirdtheme: #F6CB3D;
  --firstColor: #d9dca5;
  --secondcolor: #d9dca5;
  --thirdcolor: #4f5636;
  --fourthcolor: #4fa8d9;
  --fifthcolor: #8ac2cb;
  --whitecolor: #FDFDFD;
  --blackcolor: #000000;
  --blueColor: #1B72AB;
  --logoblue: #384b75;
}