@import "../src/CSS/Color.css";
@import "../src/CSS/responsive.css";

@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
}

ul,
li {
  margin: 0;
  padding: 0;

}

.row {
  overflow: hidden;
}

body {
  box-sizing: border-box;
  font-family: 'Cairo', sans-serif;
}

.custom-img {
  width: 100%;
  height: auto;
}

.text-justify {
  text-align: justify;
}

a {
  text-decoration: none;
  color: var(--blackcolor);
}

.slick-slide img {
  display: block;
  width: 100%;
  height: auto;
}

/* header css */
.homepage {
  margin-top: -20px;
}

.corner-sets {
  z-index: -1 !important;
}

.header-repted-img {
  background-image: url("../src/Images/trangle.png");
  padding: 10px;
  margin-top: -1px;
}

.home-link a {
  font-weight: 700;
  padding-left: 1rem !important;
  font-size: 1.2rem !important;
  text-transform: capitalize;
  text-decoration: none;
}


.exp {
  font-weight: bold;
}

.border-radius {
  border-radius: 20px;
}

.products-img {
  text-align: center;
  margin: auto;
}

/* .slider-one {
  background: url(../src/Images/banner/06.jpg);
  background-repeat: no-repeat;
  width: auto;
  height: 450px; 
  background-position: center center;
} */

/* .slider-two {
  background: url(../src/Images/banner/slider0ne.jpg); 
  background-repeat: no-repeat; 
  background-position: center center;
  width: auto;
  height: 450px;
} */

.slider-content {
  align-items: center;
  color: var(--themeMain);
  top: 20%;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
}

.slider-content h2 {
  font-size: 5rem;
  font-family: "Italianno", cursive;
}

.slider-content p {
  font-size: 1.5rem;
}

.top-10 {
  top: -20%;
  transform: rotate(0deg);
}

.toggle-left {
  position: relative;
  left: 77%;
}

.cards {
  justify-content: center;
}

.cards-bg {
  background-color: var(--blueColor);
  color: var(--whitecolor);
}

.header-background {
  background-color: var(--themeMain);
}

/* //about section */
.about-section {
  /* background-image: url(../src/Images/banner/section-bg.jpg); */
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin-top: -1rem;
}

.about-heading {
  font-size: 3.5rem;
  font-weight: bold;
  /* text-transform: uppercase; */
}

.about-paragraph {
  margin-top: 1.6rem;
  font-size: 1.2rem;
  color: var(--blackcolor);
}

.products-btn {
  padding: 0.7rem 2rem 0.7rem 2rem;
  background-color: var(--blueColor);
  color: var(--whitecolor);
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  /* padding-left: 2rem; 
  padding-right: 2rem; */
}

/* .icon-img {
  margin-top: 2.5rem;
} */

.icon-img img {
  margin-left: 0.4rem;
  width: 20px;
  height: auto;
}

/* //hero-section */

.shymag {
  background-color: var(--themeMain);
  font-size: 3rem;
}

.hero-heading {
  margin-top: 1.8rem;
  font-size: 2.5rem;
  font-weight: 900;
  text-transform: uppercase;
  color: var(--whitecolor);
}

.slider-btn {
  background-color: var(--blueColor);
  color: var(--whitecolor);
}

.slider-section {
  align-items: center;
}

.hero-img {
  padding-top: 3rem;
  background-image: url(../src/Images/bg-1920.png);
  padding-bottom: 3rem;
}

.zoom-in-zoom-out {
  animation: zoom-in-zoom-out 3s ease-out infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(1, 1);
  }
}

/* .hero-img-center {
  width: 350px; 
  height: auto;
}  */

.icon-content li {
  padding-left: 1rem;
}

/* animation effect */

.box {
  animation: animName 5s linear infinite;
}

@keyframes animName {
  0% {
    transform: rotate(0deg);
  }

  20% {
    transform: rotate(8deg);
  }
}

.products-img:hover {
  transform: scale(1.1);
  transition: 2000ms;
}

.round-card-section {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.cards-btn {
  background-color: var(--blueColor);
  color: var(--whitecolor);
}

.round-card {
  background-color: var(--themeMain);
  padding: 2rem;
}

.products-section {
  /* background: url(../src/Images/bg.jpg); */
  background-position: center;
}

.products-section-heading {
  background-attachment: fixed;
  padding-bottom: 0.9rem;
  padding-top: 3rem;
  font-size: 3rem;
  font-weight: 700;
}

.display-table-cell {
  display: table-cell;
}

.about-subheading {
  margin-bottom: 2.5rem !important;
}

.shapes {
  background-color: var(--themeMain);
  padding: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

/* .gradients {
  margin-bottom: 3.9rem;
} */

.team-img {
  clip-path: polygon(0% 0%, 100% 0%, 100% 80.25%, 0% 100%);
}

.align-middle {
  align-items: center;
}

.team-bg {
  background-color: var(--themeMain);
}

/* .products-slider .slick-next:before, .slick-prev:before {
  color:var(--blackcolor) !important;
}  */

.products-slider {

  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  padding-bottom: 3rem;
}

.products-slider.slick-next:before,
.slick-prev:before {
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: var(--blackcolor) !important;
}

.slick-next:before,
.slick-prev:before {
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: #000 !important;
}


.homepage .slick-next {
  right: 50px;
}

.homepage .slick-prev {
  left: 5%;
  z-index: 111111;
}

.testmoinal-slider {
  background-image: url(../src/Images/s1.jpg);
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  padding: 2rem 0rem 2rem 0rem;
}

.homepage .slick-dots {
  bottom: 40px;
}

.homepage .slick-dots li {
  background-color: var(--whitecolor);
  padding: 2px 5px 2px 2px !important;
}


.testimonial-img {
  width: 250px;
  height: auto;
}

.testmoinal-slider .d-flex {
  align-items: center;
}

.carousel-indicators {
  display: none !important;
}

/* .carousel-control-next,
.carousel-control-prev {
  bottom: 150px !important;
}  */

.testiomonial-logo {
  width: 250px;
  height: auto;
}

.logo-area {
  z-index: 1111;
}

/* .carousel-control-next-icon {
    display: none;
  } 

  .carousel-control-prev-icon {
    display: none;
  }  */

.quotes {
  font-size: 8rem !important;
  color: var(--whitecolor);
}

.quotes-p {
  font-size: 1.4rem;
  padding: 1rem 7rem 1rem 7rem;
}

.all-products {
  background-color: var(--secondtheme);
}

.form-icon {
  align-items: center;
  justify-content: space-between;
}

.form-icon .bi {
  font-size: 1.5rem;
}

.form-icon span {
  font-size: 1.1rem;
}

.footer-section {
  padding-top: 2rem;
}

.footer-row {
  padding-bottom: 1rem;
  padding-top: 3rem;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.icon-area {
  justify-content: space-around;
}

.footer-section-2 {
  background-color: var(--themeMain);
}

.icon-section {
  justify-content: space-evenly;
  font-size: 1.8rem !important;
}

.footer-link a {
  text-decoration: none !important;
  padding: 7px;
}

.footer-menu {
  padding-top: 2rem;
}

.footer-logo img {
  width: 100px;
  height: auto;
}

.contact-us-bg {
  background-image: url(../src/Images/bg-1920.png);
}

.form-btn {
  background-color: var(--themeMain);
  color: var(--whitecolor);
}

.form-bg {
  background-color: var(--themeMain);
}

.form-icons {
  border: 1px solid;
  padding: 0px 8px 0px 8px;

}

/* dropdown-menu */
/* .dropbtn {
  background-color: #04aa6d;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
} */

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 260px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 3px 11px;
  border-bottom: 1px solid var(--themeMain);
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.text-blue {
  color: var(--themeMain);
}

.mission-row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.yellow-bg {
  background-color: var(--themeMain);
}

.transform {
  transform: rotate(180deg);
}

.main-column {
  display: table;
}

.child-coloum {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.misson-section {
  background-color: var(--themeMain);
}

.misson-section .col-md-6 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.number-client ul .number {
  font-size: 4.5rem;
  font-weight: bolder;
  color: var(--themeMain);
}

.gradient-row {
  align-items: center;
}

.gradient-section {
  margin-top: 2rem;
}

.products-category {
  margin-top: 2.5rem;
}

.products-single {
  padding: 1.5rem 1.5rem 0rem 1.5rem;
}

.products-section a {
  text-decoration: none;
  color: var(--blackcolor);
}

.equal {
  flex: 1;
}

.mobile-gap {
  margin-top: 1.2rem !important;
}

.border-design {
  margin: auto;
  width: 100px;
  height: 1px;
  border: 1px solid var(--blueColor);
}

.align-item-center {
  align-items: center;
}

.chips-design {
  display: inline-block;
  width: 100px;
  height: 1px;
  /* color: var(--themeMain);  */
  /* background-color: var(--themeMain); */
  border: 1px solid var(--whitecolor);
}

.cards-border {
  width: 140px;
  height: 1px;
  border: 1px solid var(--themeMain);
}

.footer-section-2 ul li a {
  text-decoration: none;
}

.footer-section-2 ul li {
  margin-bottom: 6px;
}

.chips-section {
  background-color: var(--themeMain);
  color: var(--whitecolor);
}

.potato {
  font-size: 5rem;
  line-height: 70px;
}

.chips-btn {
  color: var(--whitecolor);
  font-size: 1.6rem;
  width: 100%;
  /* clip-path: polygon(0 0, 100% 0%, 94% 100%, 7% 100%); 
  font-weight: 700; */
}

.chips-btn:hover {
  background-color: var(--themeMain);
  color: var(--whitecolor);
}

.pt-c {
  font-size: 3rem;
}

.shadow-section {
  background-image: url(../src/Images/bg-chips.png);
}

.products-section .products-btn {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

/* accordian design  */

.accordion-button:not(.collapsed) {
  background-color: var(--whitecolor);
}

.main-poin-heading {
  font-weight: 800;
  padding: 2px 0px 2px 8px;
  border-bottom: 2px solid #384b75;
  border-radius: 0px 0px 14px 14px;
  background-image: linear-gradient(to right, var(--blueColor), var(--themeMain));
  color: #fff;
}

.foot-heading {
  font-size: 22px;
  font-weight: 700;
}

/* ----------------------animation---------------- */
.image-animat-container {
  animation: loader 10s infinite;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

@keyframes loader {
  0% {
    rotate: 0deg;
  }

  40% {
    rotate: 180deg;
  }

  70% {
    rotate: 260deg;
  }

  100% {
    rotate: 360deg;
  }
}

/* ----------------------floating icon ---------------------*/
.icon-bar {
  position: fixed;
  top: 60%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.icon-bar a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

.icon-bar a:hover {
  background-color: #000;
}

.facebook {
  background: #3B5998;
  color: white;
}

.twitter {
  background: #55ACEE;
  color: white;
}

.google {
  background: #dd4b39;
  color: white;
}

.linkedin {
  background: #007bb5;
  color: white;
}

.youtube {
  background: #bb0000;
  color: white;
}

.whatsapp {
  background-color: #198754;
  color: white;
}

.content {
  margin-left: 75px;
  font-size: 30px;
}

.header-shadow {
  box-shadow: -4px 0px 4px 0px #000 !important;
  z-index: 1;
}


.call-button {
  padding: 8px 35px;
  border-radius: 48px 0px;
  border: 0px solid #6C8003;
}

@keyframes blink {

  0%,
  100% {
    background-color: #3F06FF;
  }

  50% {
    background-color: #8EDDBE;
  }
}

.call-button {
  background-color: #8EDDBE;
  color: white;
  animation: blink 1s linear infinite;
}


/*completed 10 years style */

.ten-main {
  /* background: var(--themeMain); */
  padding: 1em;
  margin: 1.5em auto;
  /* border: 3px solid rgba(0, 0, 0, 0.08); */
}

.ten-heading h1 {
  text-align: center;
  font-size: 3em;
  font-weight: 600;
  color: #222;
  letter-spacing: 1px;
  text-transform: uppercase;

  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-template-rows: 27px 0;
  grid-gap: 20px;
  align-items: center;
}


.ten-heading h1:after,
.ten-heading h1:before {
  content: " ";
  display: block;
  border-bottom: 1px solid var(--themeMain);
  border-top: 1px solid var(--themeMain);
  height: 5px;
  background-color: #f8f8f8;
}


.bottom-button {
  position: fixed;
  bottom: 50px;
  right: 5px;
  z-index: 100;
}

.phone-and-whatsapp {
  position: fixed;
  bottom: 90px;
  right: 5px;
  z-index: 100;

}

.what-icon {
  display: block;
  width: 50px;
  height: 39px;
  bottom: 20px;
  color: white;
  background-color: #198754;
  border-radius: 10px;
  text-align: center;
  font-size: 25px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;

}

.call-btn {
  display: block;
  width: 50px;
  height: 39px;
  bottom: 20px;
  color: white;
  background-color: #198754;
  border-radius: 10px;
  text-align: center;
  font-size: 25px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;

}

.message-icon {
  width: 50px;
  height: 39px;
  bottom: 20px;
  color: white;
  background-color: var(--blueColor);
  border-radius: 10px;
  text-align: center;
  font-size: 25px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}


.apply-btn {
  background-color: var(--blueColor);
  color: var(--whitecolor);
  padding: 0.5rem;
  border-radius: 10px;
}

.btn-padding {
  padding: 10px 20px 10px 20px;
}

.mbl-call-icon {
  bottom: 20px;
  position: fixed;
  right: 0;
}

.img-shadows {
  box-shadow: 1px 1px 4px 0px;
  border-radius: 3px;
}

.mbl-call-icon img {
  height: 65px;
  width: 65px;
}

.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  30% {
    opacity: 1;
  }

  70% {
    opacity: 0;
  }

  90% {
    opacity: 1;
  }
}